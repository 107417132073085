import {
  ledgerEthereum,
  ledgerLiveEthereum,
  ledgerEthereumClassic,
  ledgerLiveEthereumClassic,
  ropsten,
  rskMainnet,
  rskTestnet,
  thundercore,
  solidum,
  goChain,
  xdcnetwork
} from '../configs/configPaths';
export default [
  ledgerEthereum,
  ledgerLiveEthereum,
  ledgerEthereumClassic,
  ledgerLiveEthereumClassic,
  ropsten,
  rskMainnet,
  rskTestnet,
  thundercore,
  solidum,
  goChain,
  xdcnetwork
];
